@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&family=Josefin+Sans");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.editableContain {
  background-color: rgba(138, 64, 132, 0);
}
.editableContain:hover {
  cursor: pointer;
  background-color: #59d4fa6e;
  transition: all 0.3s ease-in;
}

#root {
  height: 100%;
}
